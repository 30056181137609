import { trackLanguageChange } from "analyticsEvents";
import colors from "colors";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

function LanguageSwitch({
	fontColor = colors.neutral["pure-50"],
	changeLanguage
}: {
	fontColor?: string;
	changeLanguage: (lng: string) => void;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const { i18n } = useTranslation();

	const dropdownRef = useRef<HTMLDivElement>(null);

	const toggleDropdown = () => setIsOpen((prev) => !prev);

	const handleSelect = (lang: string) => {
		changeLanguage(lang);
		setIsOpen(false);
		trackLanguageChange(lang);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	return (
		<div
			ref={dropdownRef}
			className="language-switch-dropdown"
			style={{ color: fontColor }}
		>
			<button className="chip-button" onClick={toggleDropdown}>
				{i18n.language} <span className="arrow">▼</span>
			</button>
			{isOpen && (
				<div className="dropdown-menu">
					<div className="dropdown-options" onClick={() => handleSelect("pt")}>
						pt
					</div>
					<div className="dropdown-options" onClick={() => handleSelect("en")}>
						en
					</div>
				</div>
			)}
		</div>
	);
}

export default LanguageSwitch;
