import { useTranslation } from "react-i18next";
import "./styles.scss";

export default function NotFound() {
	const { t } = useTranslation();

	return (
		<section className="case-section" data-bgcolor="white" id="cases">
			<h2 className="title">{t("not_found")}</h2>
		</section>
	);
}
