import { ImgJacque } from "assets/images";
import ImgJacqueMobile from "assets/images/img-jacque-mobile";
import { useWindowSize } from "hooks";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./styles.scss";

import { motion, useAnimation } from "framer-motion";

import ScrollToNextSection from "components/ScrollToNextSection";
import { useInView } from "react-intersection-observer";

function AboutSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const jacqueImage = useMemo(
		() =>
			width > 1200 ? (
				<ImgJacque height="430" width="340" />
			) : (
				<ImgJacqueMobile />
			),
		[width]
	);

	const { ref, inView } = useInView({ threshold: 0.5 });
	const [hasAnimated, setHasAnimated] = useState(false);
	const animationControls = useAnimation();

	React.useEffect(() => {
		if (inView && !hasAnimated) {
			animationControls.start({ opacity: 1, y: 0 });
			setHasAnimated(true);
		}
	}, [inView, hasAnimated]);

	return (
		<section
			ref={ref}
			className="about-section"
			data-bgcolor="white"
			id="sobre"
		>
			<motion.div
				animate={animationControls}
				initial={{ opacity: 0, y: 50 }}
				transition={{ duration: 0.6 }}
				className="flex--column flex-justify--between"
			>
				<div className="about-wrapper">
					<div className="presentation">
						<h1>
							<div className="first-line">{t("hey")}</div>
						</h1>
						<div>
							<p>
								<Trans i18nKey="first_presentation_paragraph" />
							</p>
							<p>
								<Trans i18nKey="second_presentation_paragraph" />
							</p>
							<p>
								<Trans i18nKey="third_presentation_paragraph" />
							</p>
						</div>
					</div>
					<div className="image">{jacqueImage}</div>
				</div>
				<ScrollToNextSection />
			</motion.div>
		</section>
	);
}

export default AboutSection;
