import { IcoArrowDown } from "assets/icons";
import {
	ImgDiaDasMaesMmbbFooter1,
	ImgDiaDasMaesMmbbFooter2,
	ImgNaturaHomemFooter1,
	ImgTodasAsFloresFooter1,
	ImgTodasAsFloresFooter2,
	ImgTodasAsFloresFooter3
} from "assets/images";
import InstagramEmbed from "components/InstagramEmbed";
import SpotifyEmbed from "components/SpotifyEmbed";
import VideoPlayer from "components/VideoPlayer";
import { Trans, useTranslation } from "react-i18next";
import todasAsFloresPoster from "../assets/videos/cases/todas-as-flores/todas-as-flores-poster.jpg";
import todasAsFloresVideo from "../assets/videos/cases/todas-as-flores/todas-as-flores.mp4";

function useWorkCases() {
	const { t } = useTranslation();

	return [
		{
			slug: "todas-as-flores",
			title: t("cases.todas_as_flores.title"),
			client: "Natura",
			year: "2022",
			mainMaterial: (
				<div className="video">
					<VideoPlayer
						url={todasAsFloresVideo}
						poster={todasAsFloresPoster}
						captionsUrl="/captions/todas-as-flores.vtt"
					/>
					<div className="video-caption">
						{t("cases.todas_as_flores.video_caption")}
					</div>
				</div>
			),
			goal: t("cases.todas_as_flores.goal"),
			challenge: t("cases.todas_as_flores.challenge"),
			strategy: t("cases.todas_as_flores.strategy"),
			footerMaterial: (
				<div className="center-content gap-5 flex-wrap">
					<InstagramEmbed url="https://www.instagram.com/p/CyivhdILY2X/?hl=pt-br" />
					<div className="center-content gap-5 flex-wrap">
						<ImgTodasAsFloresFooter1 />
						<ImgTodasAsFloresFooter2 />
						<ImgTodasAsFloresFooter3 />
					</div>
				</div>
			)
		},
		{
			slug: "alta-perfumaria",
			title: t("cases.alta_perfumaria.title"),
			slogan: t("cases.alta_perfumaria.slogan"),
			client: "Natura",
			year: "2024",
			mainMaterial: <VideoPlayer url="https://youtu.be/gvpl8FBmMG8" />,
			goal: t("cases.alta_perfumaria.goal"),
			challenge: t("cases.alta_perfumaria.challenge"),
			strategy: t("cases.alta_perfumaria.strategy"),
			footerMaterial: (
				<div className="center-content gap-5 flex-wrap">
					<div className="center-content gap-5 flex-wrap">
						<InstagramEmbed url="https://www.instagram.com/reel/DArViYEv7k7/" />
						<InstagramEmbed url="https://www.instagram.com/reel/DAemnlRBeoy/" />
					</div>
					<div className="center-content gap-5 flex-wrap">
						<InstagramEmbed url="https://www.instagram.com/reel/DAcKX1yx-zd/" />
						<InstagramEmbed url="https://www.instagram.com/reel/DAmR7ScviC1/" />
					</div>
				</div>
			)
		},
		{
			slug: "dia-das-maes-mmbb",
			title: t("cases.dia_das_maes_mmbb.title"),
			slogan: t("cases.dia_das_maes_mmbb.slogan"),
			client: "Natura",
			year: "2023",
			mainMaterial: <VideoPlayer url="https://youtu.be/ORR0Wv_Pf48" />,
			goal: t("cases.dia_das_maes_mmbb.goal"),
			challenge: t("cases.dia_das_maes_mmbb.challenge"),
			strategy: t("cases.dia_das_maes_mmbb.strategy"),
			footerMaterial: (
				<div className="center-content gap-5 flex-wrap">
					<div className="center-content gap-5 flex-wrap">
						<InstagramEmbed url="https://www.instagram.com/reel/CrZTVHnOG3q/" />
						<InstagramEmbed url="https://www.instagram.com/reel/CrqgmrwvPjJ/" />
					</div>
					<div className="center-content gap-5 sm:flex-wrap flex--column">
						<ImgDiaDasMaesMmbbFooter1 />
						<ImgDiaDasMaesMmbbFooter2 />
					</div>
				</div>
			)
		},
		{
			slug: "natura-homem-nos",
			title: t("cases.natura_homem_nos.title"),
			slogan: (
				<i>
					<q>{t("cases.natura_homem_nos.slogan")}</q>
				</i>
			),
			client: "Natura",
			year: "2024",
			mainMaterial: <VideoPlayer url="https://youtu.be/G4hHP4sOMf0" />,
			goal: t("cases.natura_homem_nos.goal"),
			challenge: t("cases.natura_homem_nos.challenge"),
			strategy: (
				<div>
					{t("cases.natura_homem_nos.strategy_1")}
					<br />
					<br />
					{t("cases.natura_homem_nos.strategy_2")}
					<br />
					<br />
					<div className="text-[0.5rem]">
						{t("cases.natura_homem_nos.strategy_3")}
					</div>
				</div>
			),
			footerMaterial: (
				<div className="center-content gap-5 flex-wrap">
					<InstagramEmbed url="https://www.instagram.com/reel/C-RAZkQxBqK/" />
					<InstagramEmbed url="https://www.instagram.com/reel/C97b-q-MwvD/" />
					<div className="center-content gap-5 flex-wrap">
						<ImgNaturaHomemFooter1 />
						<div className="min-w-[20rem] max-w-[35rem] w-full px-8 sm:px-0">
							<VideoPlayer url="https://youtu.be/qHMxSVEK2Js" />
						</div>
					</div>
				</div>
			)
		},
		{
			slug: "kaiak-sonar",
			title: t("cases.kaiak_sonar.title"),
			slogan: t("cases.kaiak_sonar.slogan"),
			client: "Natura",
			year: "2024",
			mainMaterial: (
				<VideoPlayer url="https://www.youtube.com/watch?v=HaO0cdXVhNQ" />
			),
			goal: t("cases.kaiak_sonar.goal"),
			challenge: t("cases.kaiak_sonar.challenge"),
			strategy: t("cases.kaiak_sonar.strategy"),
			footerMaterial: (
				<div className="center-content gap-5 flex-wrap">
					<InstagramEmbed url="https://www.instagram.com/reel/DAlq6TkpwAu/" />
					<InstagramEmbed url="https://www.instagram.com/reel/DAgOvs4pSBP/" />
					<div className="h-full rounded-[2rem] border-2 border-purplish-pure-500 min-w-[30%] center-content flex--column gap-5 p-2 pb-5 bg-neutral-pure-50">
						<div className="text-center font-body text-lg text-purplish-pure-500">
							<Trans i18nKey="cases.kaiak_sonar.spotify_embed" />
						</div>
						<IcoArrowDown />
						<SpotifyEmbed
							title="kaiak-sonar-music"
							url="https://open.spotify.com/embed/track/0y3WK9dPnYYGK5jT4kLd1C?utm_source=generator"
							width="95%"
						/>
					</div>
				</div>
			)
		},
		{
			slug: "webserie-havaianas",
			title: t("cases.webserie_havaianas.title"),
			slogan: t("cases.webserie_havaianas.slogan"),
			client: "Havaianas",
			year: "2025",
			mainMaterial: <VideoPlayer url="https://youtu.be/OAt-qI2sKco" />,
			goal: t("cases.webserie_havaianas.goal"),
			challenge: t("cases.webserie_havaianas.challenge"),
			strategy: (
				<Trans
					i18nKey="cases.webserie_havaianas.strategy"
					components={{
						0: <div />,
						1: <div />,
						2: (
							<a
								href="https://havaianas.com.br/pages/explore-as-originais-datafolha?srsltid=AfmBOopQnVfiqsnxV0STczqpJchUCZYRXsxQf-V8e1c5D43Nbpfjb7V7"
								className="underline font-bold"
							>
								<u />
								report / relatório
							</a>
						)
					}}
				/>
			),
			footerMaterial: (
				<div className="center-content gap-5 flex-wrap p-8">
					<div className="center-content gap-5 flex-wrap">
						<VideoPlayer url="https://youtu.be/OAt-qI2sKco" />
						<VideoPlayer url="https://www.youtube.com/watch?v=5UiJyT0i0Wk" />
					</div>
					<div className="center-content gap-5 flex-wrap">
						<VideoPlayer url="https://youtu.be/Qm-6PzoMIAU" />
						<VideoPlayer url="https://youtu.be/NYq-TemxZDo" />
					</div>
				</div>
			)
		}
	];
}

export default useWorkCases;
