import { trackMobileMenuOpen } from "analyticsEvents";
import colors from "colors";
import { LanguageSwitch, MobileMenu, NavLink } from "components";
import HamburgerMenu from "components/HamburguerMenu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { navBarColorMapper as colorMapper } from "./types";

function Navbar({ mainPage = true }: { mainPage?: boolean }) {
	const { t, i18n } = useTranslation();
	const changeLanguage = (lng: string) => i18n.changeLanguage(lng);

	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
		trackMobileMenuOpen();
	};
	const [currentSection, setCurrentSection] = useState<string | null>(
		mainPage ? null : "cases"
	);

	const [navBarColors, setNavBarColors] = useState({
		font: mainPage ? colors.neutral["pure-50"] : colors.purplish["low-950"],
		bg: mainPage ? colors.purplish["pure-500"] : colors.neutral["pure-50"],
		activeBg: mainPage
			? colors.yellowish["high-50"]
			: colors.purplish["pure-500"],
		activeFont: mainPage
			? colors.neutral["pure-500"]
			: colors.neutral["pure-50"]
	});

	const handleScroll = () => {
		if (!mainPage) return;

		const sections = document.querySelectorAll("section");
		const { scrollY } = window;
		let currentNavBarColors = colorMapper.white;
		let currentSectionId: string | null = "";

		sections.forEach((section) => {
			const sectionTop = section.offsetTop;
			const sectionHeight = section.offsetHeight;
			const sectionBottom = sectionTop + sectionHeight;

			if (scrollY >= sectionTop - 90 && scrollY < sectionBottom - 90) {
				currentNavBarColors =
					colorMapper?.[
						(section.getAttribute("data-bgcolor") ||
							"white") as keyof typeof colorMapper
					];
			}

			if (
				scrollY >= sectionTop - sectionHeight / 4 &&
				scrollY < sectionTop + sectionHeight
			) {
				currentSectionId = section.getAttribute("id");
			}
		});

		setCurrentSection(currentSectionId);
		setNavBarColors(currentNavBarColors);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<nav
			className="navbar"
			style={{
				backgroundColor: navBarColors.bg,
				position: mainPage ? "fixed" : "relative"
			}}
		>
			<div className="navbar-desktop">
				<div className="links-wrapper">
					<NavLink
						href={mainPage ? "#sobre" : "/#sobre"}
						isActive={currentSection === "sobre"}
						label={t("about")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("sobre")}
					/>
					<NavLink
						href={mainPage ? "#entregas" : "/#entregas"}
						isActive={currentSection === "entregas"}
						label={t("deliveries")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("entregas")}
					/>
					<NavLink
						href={mainPage ? "#trabalhos" : "/#trabalhos"}
						isActive={
							currentSection === "trabalhos" || currentSection === "cases"
						}
						label={t("works")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("trabalhos")}
					/>
					<NavLink
						href={mainPage ? "#contato" : "/#contato"}
						isActive={currentSection === "contato"}
						label={t("contact")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("contato")}
					/>
				</div>
				<LanguageSwitch
					changeLanguage={changeLanguage}
					fontColor={navBarColors.font}
				/>
			</div>
			<div className="navbar-mobile">
				<HamburgerMenu
					toggleMenu={toggleMobileMenu}
					color={navBarColors.font}
				/>
				<MobileMenu
					isOpen={isMobileMenuOpen}
					toggleMenu={toggleMobileMenu}
					currentSection={currentSection}
					t={t}
					changeLanguage={changeLanguage}
					mainPage={mainPage}
				/>
			</div>
		</nav>
	);
}

export default Navbar;
