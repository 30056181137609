import { IcoBrand, IcoEmail, IcoLinkedin } from "assets/icons";
import { useWindowSize } from "hooks";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

import { motion, useAnimation } from "framer-motion";

import { trackEmailClick, trackLinkedInClick } from "analyticsEvents";
import { useInView } from "react-intersection-observer";

function ContactSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const contactIconSize = useMemo(() => (width > 700 ? "37" : "50"), [width]);
	const brandIconSize = useMemo(() => (width > 700 ? "150" : "100"), [width]);

	const { ref, inView } = useInView({ threshold: 0.5 });
	const [hasAnimated, setHasAnimated] = useState(false);
	const animationControls = useAnimation();

	React.useEffect(() => {
		if (inView && !hasAnimated) {
			animationControls.start({ opacity: 1, y: 0 });
			setHasAnimated(true);
		}
	}, [inView, hasAnimated]);

	return (
		<section
			ref={ref}
			className="contact-section"
			data-bgcolor="yellow"
			id="contato"
		>
			<motion.div
				animate={animationControls}
				initial={{ opacity: 0, y: 50 }}
				transition={{ duration: 0.6 }}
			>
				<div className="contact-wrapper">
					<div className="call-to-action">
						<h1>
							<div className="first-line">{t("chat_part1")}</div>
							<div className="first-line bold">{t("chat_part2")}</div>
						</h1>
					</div>
					<div className="contact-options-section">
						<IcoBrand theme="purple" size={brandIconSize} fullIcon={false} />
						<div className="contact-options">
							<a
								href="mailto:jacque.lmenassa@gmail.com"
								className="contact-option"
								onClick={() => trackEmailClick()}
							>
								<IcoEmail size={contactIconSize} />
								<div className="text">jacque.lmenassa@gmail.com</div>
							</a>
							<a
								className="contact-option"
								href="https://www.linkedin.com/in/jacquelinemenassa/"
								target="_blank"
								rel="noopener noreferrer"
								onClick={() => trackLinkedInClick("Jacque")}
							>
								<IcoLinkedin size={contactIconSize} />
								<div className="text">/in/jacquelinemenassa</div>
							</a>
						</div>
					</div>
				</div>
			</motion.div>
			<div className="made-by">
				<p>
					{t("made_by")}{" "}
					<strong>
						<a
							href="https://www.linkedin.com/in/brenno-novais/"
							target="_blank"
							rel="noopener noreferrer"
							className="linkedin-link"
							onClick={() => trackLinkedInClick("Brenno")}
						>
							Brenno Novais
						</a>
					</strong>
				</p>
			</div>
		</section>
	);
}

export default ContactSection;
