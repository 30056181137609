import { BrowserRouter as Router, useLocation } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { logPageView } from "analytics";
import "App.scss";
import LoadingScreen from "components/LoadingScreen";
import { useEffect, useState } from "react";
import Routers from "router";

function RouteChangeTracker() {
	const location = useLocation();

	useEffect(() => {
		logPageView(location.pathname);
	}, [location]);

	return null;
}

export function App() {
	const { pathname } = window.location;
	const [loading, setLoading] = useState(!pathname.replace("/", ""));

	return (
		<Router>
			{loading && <LoadingScreen onFinish={() => setLoading(false)} />}
			<RouteChangeTracker />
			<Routers loading={loading} />
		</Router>
	);
}
