import { useEffect } from "react";

export default function SpotifyEmbed({
	title,
	url,
	width = "50%",
	height = "352"
}: {
	title: string;
	url: string;
	width?: string;
	height?: string;
}) {
	useEffect(() => {
		const script = document.createElement("script");
		script.setAttribute(
			"src",
			"https://open.spotify.com/embed-podcast/iframe-api/v1"
		);
		script.setAttribute("async", "true");
		document.body.appendChild(script);
	}, []);

	return (
		<iframe
			title={title}
			style={{ borderRadius: "12px" }}
			src={url}
			width={width}
			height={height}
			frameBorder="0"
			allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
			loading="lazy"
		/>
	);
}
