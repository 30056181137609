import { IcoBrand } from "assets/icons";
import { motion, useAnimation } from "framer-motion";
import { useWindowSize } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import ScrollToNextSection from "components/ScrollToNextSection";
import { useInView } from "react-intersection-observer";
import "./styles.scss";

function WelcomeSection({ loading }: { loading: boolean }) {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const iconSize = useMemo(
		() => (width > 1165 ? "150" : width > 768 ? "100" : "50"),
		[width]
	);

	const { ref, inView } = useInView({ threshold: 0.5 });
	const [hasAnimated, setHasAnimated] = useState(false);
	const [startSpinning, setStartSpinning] = useState(false);
	const animationControls = useAnimation();

	useEffect(() => {
		if (!loading && inView && !hasAnimated) {
			animationControls.start({ opacity: 1, y: 0 });
			setHasAnimated(true);
		}

		setTimeout(() => {
			setStartSpinning(true);
		}, 500);
	}, [loading, inView, hasAnimated]);

	return (
		<section ref={ref} className="welcome-section" data-bgcolor="purple" id="">
			<div>
				<div className="width--fit-content">
					<h1>
						<div className="first-line">
							<motion.span
								className="font-title color--yellowish-pure-500"
								animate={animationControls}
								initial={{ opacity: 0, y: 50 }}
								transition={{ duration: 0.6 }}
							>
								jacque
							</motion.span>
							<div
								id="brand-icon-welcome-section"
								className={clsx("brand-icon", {
									"spin-brand-icon": startSpinning
								})}
							>
								<IcoBrand theme="yellow" size={iconSize} />
							</div>
						</div>
						<motion.span
							className="font-title color--neutral-pure-50"
							animate={animationControls}
							initial={{ opacity: 0, y: 50 }}
							transition={{ duration: 0.6 }}
						>
							menassa
						</motion.span>
					</h1>
					<motion.p
						className="font-title color--neutral-pure-50"
						animate={animationControls}
						initial={{ opacity: 0, y: 50 }}
						transition={{ duration: 0.6 }}
					>
						| {t("strategic_planning")}
					</motion.p>
				</div>
			</div>
			<ScrollToNextSection />
		</section>
	);
}

export default WelcomeSection;
