import ReactGA from "react-ga4";

export const trackEmailClick = () => {
	ReactGA.event({
		category: "Contato",
		action: "Clique no botão de e-mail"
	});
};

export const trackLinkedInClick = (person: string) => {
	ReactGA.event({
		category: "Contato",
		action: `Clique no botão do LinkedIn: ${person}`
	});
};

export const trackLanguageChange = (language: string) => {
	ReactGA.event({
		category: "Idioma",
		action: `Mudou idioma para ${language.toUpperCase()}`
	});
};

export const trackSectionClick = (section: string) => {
	ReactGA.event({
		category: "Navegação",
		action: `Usuário clicou em '${section}'`
	});
};

export const trackMobileMenuOpen = () => {
	ReactGA.event({
		category: "Interface",
		action: "Abriu o menu mobile"
	});
};

export const trackScrolledToBottom = () => {
	ReactGA.event({
		category: "Scroll",
		action: "Usuário rolou até o final da página"
	});
};

export const trackProjectClick = (projectName: string) => {
	ReactGA.event({
		category: "Portfólio",
		action: `Clicou no projeto`,
		label: projectName
	});
};
