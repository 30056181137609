import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: {
					about: "about",
					deliveries: "deliveries",
					works: "works",
					contact: "contact",
					strategic_planning: "strategic planning",
					hey: "hey,",
					first_presentation_paragraph:
						"My name is Jacqueline Menassa, but you can call me Jacque.",
					second_presentation_paragraph:
						"I love new experiences and diving into different cultures, cuisines, and stories. I started my career in advertising as an account manager and also wrote articles for a news portal.",
					third_presentation_paragraph:
						"Throughout my journey, I have worked with clients like <strong>Natura, Vivo, Bauducco, Doriana, and Protea</strong>, always focusing on creating strategies that authentically connect brands and people.",
					chat_part1: "let's",
					chat_part2: "talk?",
					research_and_analysis_title: "research and analysis",
					research_and_analysis_description:
						"about consumer, category, market, and culture - utilizing methods such as qualitative and quantitative research, desk research, trend studies, and benchmarking.",
					strategy_title: "strategy",
					strategy_description:
						"developing communication plans, creating creative briefs, defining strategic territories, and mapping consumer journeys.",
					management_and_monitoring_title: "management and monitoring",
					management_and_monitoring_description:
						"monitoring and supporting the different areas involved in projects, analyzing results, and developing action plans.",
					goal: "Goal",
					challenge: "Challenge",
					strategy: "Strategy",
					other_works: "other works",
					not_found: "work not found :(",
					made_by: "made by",
					cases: {
						todas_as_flores: {
							title: "Todas as Flores",
							video_caption:
								"Recognized by the Profissionais do Ano award as Best Content Action",
							goal: "Leverage the narrative of the first original streaming soap opera, Todas as Flores, to deepen information and techniques about the new Essencial Ato, in a way that supports brand building.",
							challenge:
								"Show Natura's leadership in authorial perfumery with its innovative launch using headspace technology (which allows capturing the aroma of living flowers without picking them) in a way that people could understand and connect with.",
							strategy:
								"We designed the project from an entertainment perspective, not as merchandising. During the soap opera, Natura stood out organically in the storyline, emphasizing the pillars of innovation, technology, and sustainability until launching the perfume both in the show and in real life."
						},
						alta_perfumaria: {
							title: "Alta Perfumaria",
							slogan: "The greatest expression of nature in perfume.",
							goal: "Attract the attention of the upper class (AB), positioning Natura's new products as the highest expression of sophistication, and build aspiration to make them truly desirable.",
							challenge:
								"We needed to reach the AB class, which sees Natura as a well-regarded brand, but not one they identify with—it feels like 'not for me'.",
							strategy:
								"We realized we had to reach people through the subconscious, breaking through brand barriers. We explored contemporary luxury trends and highlighted their symbols in the campaign assets—plus, we took the collection to Paris Fashion Week 2024."
						},
						dia_das_maes_mmbb: {
							title: "Mother's Day MMBB",
							slogan: "Well-being starts here.",
							goal: "Increase consumer desire to give and receive a Natura gift for Mother's Day 2023.",
							challenge:
								"Bring the iconic Mamãe e Bebê brand with an impactful and thought-provoking campaign to spark conversation and emotional connection.",
							strategy:
								"In a world where mothers feel increasingly paralyzed by the worries of bringing new life into a difficult world, Natura invites us to remember that each baby is a chance to change the future—from the very first day. It's through touch that babies begin to interact with the world."
						},
						natura_homem_nos: {
							title: "Natura Homem Nós",
							slogan: "What if we took better care of each other?",
							goal: "Evolve Natura Homem's positioning, boosting engagement and brand distinction in the male universe around Father's Day.",
							challenge:
								"Launch Natura Homem Nós in a way that sparks interest, in a context where male care is still seen as a taboo.",
							strategy_1:
								"5 out of 10 teenage boys are unsure if they are loved by their fathers*. Our campaign responded to this powerful insight with a real, emotional story about care, featuring soccer player Endrick and his father Douglas.",
							strategy_2:
								"In addition to the film, we partnered with the CBF to create a groundbreaking activation in Brazilian soccer: “The Referee's Voice.”",
							strategy_3:
								"*From the study “Meninos: Sonhando os homens do futuro” by Instituto PDH and Papo de Homem, supported by Natura and the UN Global Compact."
						},
						kaiak_sonar: {
							title: "Kaiak Sonar",
							slogan:
								"Sea sounds became Kaiak Sonar. And Kaiak Sonar became music, produced by BaianaSystem.",
							goal: "Consolidate the strength of the Kaiak brand through a launch featuring unprecedented perfume technology: Kaiak Sonar, using VibraScent, which translates sounds and colors into scent.",
							challenge:
								"Bring a meaningful campaign narrative that translates innovation in a way the consumer understands. We needed to place Kaiak Sonar's innovation at the heart of culture.",
							strategy:
								"We chose the band BaianaSystem to represent Kaiak Sonar's innovation in a modern and pop way. During the Schurmann family expedition, ocean sounds and images were collected and shared with Russo Passapusso, who composed 'Espelho d'água' using musical notes aligned with the fragrance ingredients' frequencies. Sea sounds became Kaiak Sonar. And Kaiak Sonar became music.",
							spotify_embed:
								"<strong>check out the<br />song</strong> on Spotify"
						},
						webserie_havaianas: {
							title: "Havaianas Web Series",
							slogan:
								"Havaianas did a study that finally proves: we were made to slow down.",
							goal: "Help people understand that Havaianas is synonymous with taking a break and relaxing.",
							challenge:
								"We all know people feel more relaxed in Havaianas, but it was never scientifically proven.",
							strategy: `<0>We conducted a study with Datafolha and obtained concrete data showing how strongly Havaianas is associated with taking a break.</0>
<1>We then created a survey with strategic questions that intersected to build a web series and a <2>report</2> on the brand's website.</1>`
						}
					}
				}
			},
			pt: {
				translation: {
					about: "sobre",
					deliveries: "entregas",
					works: "trabalhos",
					contact: "contato",
					strategic_planning: "planejamento estratégico",
					hey: "oie,",
					first_presentation_paragraph:
						"me chamo Jacqueline Menassa, mas pode me chamar de Jacque.",
					second_presentation_paragraph:
						"Adoro novas experiências e mergulhar em diferentes culturas, culinárias e histórias. Comecei minha carreira na publicidade como atendimento e também escrevi matérias para um portal de notícias.",
					third_presentation_paragraph:
						"Em minha trajetória, atendi clientes como <strong>Natura, Vivo, Bauducco, Doriana e Protea</strong>, sempre focando em criar estratégias que conectam marcas e pessoas de maneira autêntica.",
					chat_part1: "bora bater",
					chat_part2: "um papo?",
					research_and_analysis_title: "pesquisa e análise",
					research_and_analysis_description:
						"sobre consumidor, categoria, mercado e cultura - utilizando métodos como pesquisas qualitativas, quantitativas, desk research, estudo de tendências e benchmarks.",
					strategy_title: "estratégia",
					strategy_description:
						"desenvolvendo planos de comunicação,  elaboração de briefings criativos, definição de territórios estratégicos, mapeamento de jornadas do consumidor.",
					management_and_monitoring_title: "gestão e acompanhamento",
					management_and_monitoring_description:
						"acompanhamento e suporte às diferentes áreas envolvidas nos projetos, análises de resultados e planos de ação.",
					goal: "Objetivo",
					challenge: "Desafio",
					strategy: "Estratégia",
					other_works: "outros trabalhos",
					not_found: "esse trabalho não foi encontrado :(",
					made_by: "feito por",
					cases: {
						todas_as_flores: {
							title: "Todas as Flores",
							video_caption:
								"Reconhecido pelo prêmio Profissionais do Ano como Melhor Ação de Conteúdo",
							goal: "Aproveitar a narrativa da primeira novela original para streaming, Todas as Flores, para aprofundar as informações e técnicas sobre o novo Essencial Ato, de forma a colaborar com a construção de marca.",
							challenge:
								"Mostrar o protagonismo da Natura na perfumaria autoral, com seu lançamento inovador feito de tecnologia headspace (permitindo capturar o aroma de flores vivas sem a necessidade de colhê-las) de maneira que as pessoas entendessem e se conectassem com a novidade.",
							strategy:
								"Idealizamos o projeto com um olhar de entretenimento, não de merchandising. Durante a novela, a Natura se destacou dentro da narrativa de forma orgânica, exaltando os pilares de inovação, tecnologia e sustentabilidade até lançar o perfume na novela e na vida real."
						},
						alta_perfumaria: {
							title: "Alta Perfumaria",
							slogan: "A maior expressão da natureza em perfume.",
							goal: "Atrair a atenção da classe AB, posicionando os novos produtos da Natura como a máxima expressão de sofisticação e construindo aspiracionalidade para torná-los verdadeiramente desejáveis.",
							challenge:
								"Precisávamos nos aproximar da classe AB, que enxerga a Natura como uma marca Natura bem percebida, mas não gera identificação, com uma sensação de “não é pra mim”.",
							strategy:
								"Entendemos que precisávamos capturar as pessoas pelo inconsciente, transpassando as barreiras existentes com a marca. Assim, exploraramos as tendências do luxo contemporâneo e evidenciando os signos nas peças da campanha, além de levar a coleção para o Paris Fashion Week 2024."
						},
						dia_das_maes_mmbb: {
							title: "Dia das Mães MMBB",
							slogan: "O bem estar bem começa aqui.",
							goal: "Aumentar o desejo do consumidor em dar e receber um presente Natura no Dia das Mães de 2023.",
							challenge:
								"Trazer a icônica marca Mamãe e Bebê com uma campanha impactante, questionadora para gerar conversa e conexão emocional com o público.",
							strategy:
								"Em um cenário que faz com que as mães se sintam cada vez mais paralisadas frente à preocupação de como receber uma vida nova em um mundo tão difícil, Natura convida a lembrar que cada bebê uma oportunidade de mudar o futuro, desde o primeiro dia. É por meio do toque que o bebê começa a interagir com o mundo."
						},
						natura_homem_nos: {
							title: "Natura Homem Nós",
							slogan: "E se nós cuidássemos mais uns dos outros?",
							goal: "Evoluir o posicionamento de Natura Homem, alavancando engajamento e distintividade da marca no universo masculino a partir da data.",
							challenge:
								"Lançar Natura Homem Nós gerando interesse nas pessoas, em um cenário onde ainda o cuidado masculino ainda é considerado um tabu.",
							strategy_1:
								"5 em cada 10 meninos adolescentes não têm certeza se são amados pelo pai*. Nossa campanha foi uma resposta a esse dado impactante: uma história real, emocionante, que mostra a importância do cuidado, com o jogador Endrick e seu pai Douglas.",
							strategy_2:
								"Além do filme produzido, em parceria com a CBF, fizemos a ação inédita no futebol brasileiro, A Voz do Juiz.",
							strategy_3:
								"*O estudo “Meninos: Sonhando os homens do futuro”, do Instituto PDH e Papo de Homem, e viabilizado pela Natura com apoio do Pacto Global da ONU"
						},
						kaiak_sonar: {
							title: "Kaiak Sonar",
							slogan:
								"Sons do mar viraram Kaiak Sonar. E Kaiak Sonar virou música, com produção do BaianaSystem.",
							goal: "Consolidar a força da marca Kaiak via um lançamento com tecnologia inédita na perfumaria: Kaiak Sonar - com tecnologia VibraScent, traduz sons e cores em cheiro.",
							challenge:
								"Materializar uma narrativa de campanha que traduza a inovação de uma forma mais significativa para o consumidor. Era preciso colocar a inovaçãode Kaiak Sonar no pulso da cultura.",
							strategy:
								'Escolhemos a banda BaianaSystem para traduzir a inovação de Kaiak Sonar de forma moderna e pop. Durante a expedição da família Schurmann, sons e imagens do oceano foram captados e compartilhados com Russo Passapusso, que compôs "Espelho d\'água" com notas musicais alinhadas à frequência dos ingredientes do perfume. Sons do mar viraram Kaiak Sonar. E Kaiak Sonar virou música.',
							spotify_embed: "<strong>confira a<br />música</strong> no Spotify"
						},
						webserie_havaianas: {
							title: "Websérie Havaianas",
							slogan:
								"Havaianas fez uma pesquisa que finalmente pode provar: fomos feitos para desacelerar",
							goal: "Fazer com que as pessoas entendessem que Havaianas é sinônimo de break e descanso.",
							challenge:
								"Sabemos que as pessoas ficam mais tranquilas quando estão de Havaianas, mas isso não foi comprovado por a+b.",
							strategy: `<0>Fizemos uma pesquisa com o Datafolha e tivemos dados concretos do quanto Havaianas está associado a pausa.</0>
<1>Fizemos então uma pesquisa com perguntas estratégicas que se cruzaram para criar uma websérie e um <2>relatório</2> no site da marca.</1>`
						}
					}
				}
			}
		},
		fallbackLng: "pt",
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
