import {
	AboutSection,
	ContactSection,
	DeliveriesSection,
	WelcomeSection,
	WorksSection
} from "components";
import "./styles.scss";

function MainPage({ loading }: { loading: boolean }) {
	return (
		<div className="main-page">
			<WelcomeSection loading={loading} />
			<AboutSection />
			<DeliveriesSection />
			<WorksSection />
			<ContactSection />
		</div>
	);
}

export default MainPage;
