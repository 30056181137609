import { trackScrolledToBottom } from "analyticsEvents";
import BaseLayout from "layouts/BaseLayout";
import { MainPage } from "pages";
import CasePage from "pages/Case";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

function Routers({ loading }: { loading: boolean }) {
	const { pathname } = useLocation();

	useEffect(() => {
		if (loading) return;

		const { hash } = window.location;
		if (hash) {
			setTimeout(() => {
				const element = document.querySelector(hash);
				if (element) {
					element.scrollIntoView({ behavior: "smooth" });
				}
			}, 100);
		} else {
			window.scrollTo({
				top: 0,
				left: 0
			});
		}
	}, [loading, pathname]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.scrollY;
			const windowHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;

			const isAtBottom = scrollTop + windowHeight >= documentHeight - 10;

			if (isAtBottom) {
				trackScrolledToBottom();
				window.removeEventListener("scroll", handleScroll);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<Routes>
			<Route path="/" element={<BaseLayout />}>
				<Route path="/" element={<MainPage loading={loading} />} />
			</Route>
			<Route path="/">
				<Route path="/case/:slug" element={<CasePage />} />
			</Route>
			<Route path="/*" element={<Navigate to="/" />} />
		</Routes>
	);
}

export default Routers;
