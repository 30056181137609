import { IcoBrand } from "assets/icons";
import ScrollToNextSection from "components/ScrollToNextSection";
import { useWindowSize } from "hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "./Carousel";
import "./styles.scss";

function DeliveriesSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const iconSize = useMemo(() => (width > 1165 ? "80" : "55"), [width]);

	return (
		<section className="deliveries-section" data-bgcolor="white" id="entregas">
			<div className="page-title">
				<IcoBrand theme="yellow" size={iconSize} />
				<div className="title">{t("deliveries")}</div>
			</div>
			<Carousel />
			<ScrollToNextSection />
		</section>
	);
}

export default DeliveriesSection;
