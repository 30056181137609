import { IcoBrand } from "assets/icons";
import { useWindowSize } from "hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";

export default function LoadingScreen({ onFinish }: { onFinish: () => void }) {
	const iconRef = useRef<HTMLDivElement>(null);
	const [startTransition, setStartTransition] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);

	const { width } = useWindowSize();

	const iconSize = useMemo(
		() => (width > 1165 ? "150" : width > 768 ? "100" : "50"),
		[width]
	);

	useEffect(() => {
		const pulseDuration = 2000;
		const moveDuration = 1000;

		const timeout = setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0
			});

			const target = document.getElementById("brand-icon-welcome-section");
			const icon = iconRef.current;

			if (target && icon) {
				const targetRect = target.getBoundingClientRect();
				const iconRect = icon.getBoundingClientRect();

				const translateX = targetRect.x - iconRect.x + 10;
				const translateY = targetRect.y - iconRect.y + 10;

				const keyframes = `
          @keyframes moveUp {
            from {
              transform: translate(0, 0) scale(1.2);
            }
            to {
              transform: translate(${translateX}px, ${translateY}px) rotate(29deg) scale(1);
            }
          }
        `;

				const styleSheet = document.createElement("style");
				styleSheet.innerHTML = keyframes;
				document.head.appendChild(styleSheet);

				setStartTransition(true);

				setTimeout(() => {
					onFinish();
				}, moveDuration);
			}
		}, pulseDuration);

		return () => clearTimeout(timeout);
	}, [loaded]);

	useEffect(() => {
		const timeout = setTimeout(() => setLoaded(true), 30000);
		return () => clearTimeout(timeout);
	}, []);

	document.fonts.ready.then(() => setLoaded(true));

	return (
		<div className="loading-screen">
			<div
				ref={iconRef}
				className={`loading-icon pulse ${
					loaded && startTransition ? "move-to-top" : "pulse"
				}`}
			>
				<IcoBrand theme="yellow" size={iconSize} />
			</div>
		</div>
	);
}
