type VideoPlayerProps = {
	url: string;
	captionsUrl?: string;
	poster?: string;
};

export default function VideoPlayer({
	url,
	captionsUrl,
	poster
}: VideoPlayerProps) {
	if (!url) return null;

	const isYouTube = url.includes("youtube.com") || url.includes("youtu.be");
	const isInstagram = url.includes("instagram.com");
	const isMP4 = url.endsWith(".mp4");

	if (isYouTube) {
		const videoId = url.split("v=")[1]?.split("&")[0] || url.split("/").pop();
		return (
			<iframe
				className="w-full aspect-video rounded-2xl"
				src={`https://www.youtube.com/embed/${videoId}`}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		);
	}

	if (isInstagram) {
		return (
			<iframe
				className="w-full aspect-square rounded-2xl"
				src={`${url}embed`}
				title="Instagram video"
				frameBorder="0"
				allowFullScreen
			/>
		);
	}

	if (isMP4) {
		return (
			<video className="w-full rounded-2xl" controls poster={poster}>
				<source src={url} type="video/mp4" />
				<track
					src={captionsUrl}
					kind="captions"
					srcLang="pt"
					label="Português"
					default
				/>
			</video>
		);
	}

	return <p className="text-red-500">Formato de vídeo não suportado: {url}</p>;
}
