import { IcoBrand } from "assets/icons";
import { useTranslation } from "react-i18next";
import "./styles.scss";

type CaseSectionProps = {
	title: string;
	slogan?: string | JSX.Element;
	client: string;
	year: string;
	mainMaterial: JSX.Element;
	goal: string | JSX.Element;
	challenge: string | JSX.Element;
	strategy: string | JSX.Element;
	footerMaterial: JSX.Element;
};

export default function CaseSection({
	title,
	slogan,
	client,
	year,
	mainMaterial,
	goal,
	challenge,
	strategy,
	footerMaterial
}: CaseSectionProps) {
	const { t } = useTranslation();

	return (
		<>
			<section className="case-section" data-bgcolor="white" id="cases">
				<h2 className="title">{title}</h2>
				<h3 className="slogan">{slogan}</h3>
				<hr className="division-line" />

				<div className="client-year">
					{client} | {year}
				</div>

				{mainMaterial}
				<div className="brand">
					<IcoBrand theme="yellow" size="50" />
				</div>

				<div className="definitions">
					<div>
						<h3 className="definition-title">{t("goal")}</h3>
						<div className="definition-description">{goal}</div>
					</div>
					<div>
						<h3 className="definition-title">{t("challenge")}</h3>
						<div className="definition-description">{challenge}</div>
					</div>
					<div>
						<h3 className="definition-title">{t("strategy")}</h3>
						<div className="definition-description">{strategy}</div>
					</div>
				</div>
			</section>
			<footer>{footerMaterial}</footer>
		</>
	);
}
