import { IcoBrand } from "assets/icons";
import { Navbar } from "components";
import CaseSection from "components/CaseSection";
import WorksCarousel from "components/WorksSection/WorksCarousel";
import { useWorkCases } from "hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import "./styles.scss";

function CasePage() {
	const { slug } = useParams();
	const cases = useWorkCases();
	const { t } = useTranslation();

	const caseData = cases.find((c) => c.slug === slug);

	return (
		<div className="case-page">
			<Navbar mainPage={false} />
			{caseData ? <CaseSection {...caseData} /> : <NotFound />}
			<div className="other-cases">
				<div className="mb-[5%]">
					<IcoBrand theme="purple" size="35" />
				</div>
				<hr className="other-works-division-line" />
				<div className="other-cases-title">{t("other_works")}</div>
				<WorksCarousel />
			</div>
		</div>
	);
}

export default CasePage;
