import { IcoBrand } from "assets/icons";
import ScrollToNextSection from "components/ScrollToNextSection";
import { useWindowSize } from "hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import WorksCarousel from "./WorksCarousel";
import "./styles.scss";

function WorksSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const iconSize = useMemo(() => (width > 1165 ? "80" : "55"), [width]);

	return (
		<section className="works-section" data-bgcolor="white" id="trabalhos">
			<div className="page-title">
				<IcoBrand theme="yellow" size={iconSize} />
				<div className="title">{t("works")}</div>
			</div>
			<WorksCarousel />
			<ScrollToNextSection />
		</section>
	);
}

export default WorksSection;
