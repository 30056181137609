import { useEffect, useRef } from "react";
import "./styles.scss";

export default function InstagramEmbed({ url }: { url: string }) {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if ((window as any).instgrm?.Embeds?.process) {
				(window as any).instgrm.Embeds.process();
			}
		}, 0);

		return () => clearTimeout(timeout);
	}, [url]);

	return (
		<div ref={containerRef}>
			<blockquote
				className="instagram-media"
				data-instgrm-permalink={url}
				data-instgrm-version="14"
				style={{ width: "100%", margin: "0 auto" }}
			/>
		</div>
	);
}
