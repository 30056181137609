import {
	ImgAltaPerfumariaMain,
	ImgDiaDasMaesMmbbMain,
	ImgKaiakSonarMain,
	ImgNaturaHomemMain,
	ImgTodasAsFloresMain,
	ImgWebserieHavaianasMain
} from "assets/images";
import { motion, useAnimation } from "framer-motion";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { trackProjectClick } from "analyticsEvents";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";

const projects = [
	{
		title: "cases.natura_homem_nos.title",
		category: "NATURA | 2024",
		image: <ImgNaturaHomemMain />,
		path: "/case/natura-homem-nos"
	},
	{
		title: "cases.webserie_havaianas.title",
		category: "HAVAIANAS | 2025",
		image: <ImgWebserieHavaianasMain />,
		path: "/case/webserie-havaianas"
	},
	{
		title: "cases.dia_das_maes_mmbb.title",
		category: "NATURA | 2023",
		image: <ImgDiaDasMaesMmbbMain />,
		path: "/case/dia-das-maes-mmbb"
	},
	{
		title: "cases.alta_perfumaria.title",
		category: "NATURA | 2024",
		image: <ImgAltaPerfumariaMain />,
		path: "/case/alta-perfumaria"
	},
	{
		title: "cases.todas_as_flores.title",
		category: "NATURA | 2022",
		image: <ImgTodasAsFloresMain />,
		path: "/case/todas-as-flores"
	},
	{
		title: "cases.kaiak_sonar.title",
		category: "NATURA | 2024",
		image: <ImgKaiakSonarMain />,
		path: "/case/kaiak-sonar"
	}
];

export default function WorksCarousel() {
	const { ref, inView } = useInView({ threshold: 0.5 });
	const [hasAnimated, setHasAnimated] = useState(false);
	const animationControls = useAnimation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { slug } = useParams();

	React.useEffect(() => {
		if (inView && !hasAnimated) {
			animationControls.start({ opacity: 1, y: 0 });
			setHasAnimated(true);
		}
	}, [inView, hasAnimated]);

	return (
		<div ref={ref} className="works-carousel">
			<motion.div
				animate={animationControls}
				initial={{ opacity: 0, y: 50 }}
				transition={{ duration: 0.6 }}
			>
				<Swiper
					modules={[Navigation, Pagination, Grid]}
					pagination={{ clickable: true }}
					navigation
					breakpoints={{
						0: {
							slidesPerView: 1,
							grid: { rows: 6, fill: "row" }
						},
						640: {
							slidesPerView: 2,
							grid: { rows: 3, fill: "row" }
						},
						980: {
							slidesPerView: 3,
							grid: { rows: 2, fill: "row" }
						}
					}}
					slidesPerView={3}
					spaceBetween={20}
					style={
						{
							"--swiper-navigation-size": "25px"
						} as any
					}
				>
					{projects.map((project, index) =>
						!slug || !project.path.includes(slug) ? (
							<SwiperSlide key={index}>
								<div
									className="works-card"
									onClick={() => {
										trackProjectClick(project.title);
										navigate(project.path);
									}}
								>
									<div className="work-image">{project.image}</div>
									<div className="work-overlay" />
									<div className="work-texts">
										<h3 className="work-title">{t(project.title)}</h3>
										<p className="work-description">{project.category}</p>
									</div>
								</div>
							</SwiperSlide>
						) : null
					)}
				</Swiper>
			</motion.div>
		</div>
	);
}
