import colors from "colors";
import { IcoProps } from "./types";

export default function IcoArrowDown(props: IcoProps) {
	const { color = colors.purplish["pure-500"] } = props;
	return (
		<svg
			width="16"
			height="28"
			viewBox="0 0 16 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.29289 27.7071C7.68342 28.0976 8.31658 28.0976 8.70711 27.7071L15.0711 21.3431C15.4616 20.9526 15.4616 20.3195 15.0711 19.9289C14.6805 19.5384 14.0474 19.5384 13.6569 19.9289L8 25.5858L2.34314 19.9289C1.95262 19.5384 1.31946 19.5384 0.928931 19.9289C0.538407 20.3195 0.538407 20.9526 0.928931 21.3431L7.29289 27.7071ZM7 -4.37114e-08L7 27L9 27L9 4.37114e-08L7 -4.37114e-08Z"
				fill={color}
			/>
		</svg>
	);
}
