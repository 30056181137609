import { IcoArrowDown } from "assets/icons";
import clsx from "clsx";
import colors from "colors";
import { useEffect, useState } from "react";
import "./styles.scss";

function ScrollToNextSection() {
	const [nextSectionId, setNextSectionId] = useState<string | null>(null);
	const [currentBgColor, setCurrentBgColor] = useState("white");

	useEffect(() => {
		const updateSectionData = () => {
			const sections = Array.from(document.querySelectorAll("section"));
			const { scrollY } = window;

			for (let i = 0; i < sections.length - 1; i += 1) {
				const current = sections[i];
				const next = sections[i + 1];

				const top = current.offsetTop;
				const bottom = top + current.offsetHeight;

				if (scrollY >= top - 90 && scrollY < bottom - 90) {
					setNextSectionId(next.id);
					setCurrentBgColor(current.dataset.bgcolor || "white");
					break;
				}
			}
		};

		window.addEventListener("scroll", updateSectionData);
		updateSectionData();

		return () => {
			window.removeEventListener("scroll", updateSectionData);
		};
	}, []);

	const scrollToNext = () => {
		if (!nextSectionId) return;
		document
			.getElementById(nextSectionId)
			?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className="scroll-next" onClick={scrollToNext}>
			<div className={clsx("circle", currentBgColor)}>
				<IcoArrowDown color={colors.neutral["pure-50"]} />
			</div>
		</div>
	);
}

export default ScrollToNextSection;
